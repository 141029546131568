.conf-btn{
  margin-top: 1.8rem;
}
.conf-date-picker{
    /* display:flex;
    justify-content: center; */
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
}
.conf-grid{
    /* margin-top: 1rem;
    margin-bottom: 1rem; */
}
.conf-text-fild-all{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* margin-right: 1rem; */
}
.conf-text-fild{
  margin-left: 1rem;
  margin-bottom: 1.5rem;
}

